
import { useEffect, useState } from 'react';
import { HeaderProfile } from '../../components/Profile/HeaderProfile';
import { getProfile, saveProfile } from '../../http/userAPI';
import { EmailIcon, FacebookIcon, InstagramIcon, TelegramIcon, YoutubeIcon } from '../../ui-kit/assets';
import { Input, Textarea } from '../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../utils/consts';
import { useDispatch } from 'react-redux'

import styles from './Profile.module.scss';

export const Profile = () => {

  const dispatch = useDispatch()
  
  const [ profile, setProfile ] = useState({})
  const [ nickname, setNickname ] = useState('')
  const [ about, setAbout ] = useState('')

  const [ social_telegram, setTg ] = useState('')
  const [ social_facebook, setFb ] = useState('')
  const [ social_youtube, setYoutube ] = useState('')
  const [ social_email, setEmail ] = useState('')
  const [ social_instagram, setInst ] = useState('')
  const [ button_text, setButtonText ] = useState('Сохранить')

  const load_data = async() => {
    let data = await getProfile()
    setNickname(data.nickname)
    setAbout(data.about)
    setTg(data.social_telegram)
    setFb(data.social_facebook)
    setYoutube(data.social_youtube)
    setEmail(data.social_email)
    setInst(data.social_instagram)
    setProfile(data)
  }
  
  const updateProfile = async() => {
    setButtonText('Сохранение ...')
    try {
      let data = await saveProfile(nickname, about, social_telegram, social_facebook, social_youtube, social_email, social_instagram)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }

    setTimeout(() => {
      setButtonText('Сохранено')
    },500)


    setTimeout(() => {
      setButtonText('Сохранить')
    },1000)
  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <div className={styles.body}>

      <HeaderProfile/>

      <div className={styles.profile}>

        <div className={styles['item-profile']}>
          <div className={styles['item-profile-title']}>Настройки профиля</div>
          <div>
            <div className={styles['field-title']}>Никнейм до 20 символов</div>
            <div>
              <Input defaultValue={profile?.nickname} setInputValue={setNickname} inputClassName={styles.input} className={styles['input-container']} />
            </div>
            <div className={styles['field-title']}>Расскажите о себе</div>
            <div>
              <Textarea defaultValue={profile?.about} setTextareaValue={setAbout} className={styles['textarea-container']} textareaClassName={styles['textarea']} />
            </div>
          </div>
        </div>

        <div className={styles['item-profile']}>
          <div className={styles['item-profile-title']}>Социальные сети</div>
          <div className={styles['socila-item']}>
            <div className={styles['socila-item-icon']}><img src={TelegramIcon}/></div>
            <div className={styles['socila-item-input']}><Input defaultValue={profile?.social_telegram} label="Telegram" setInputValue={setTg} inputClassName={styles.input} className={styles['input-container']} /></div>
          </div>
          <div className={styles['socila-item']}>
            <div className={styles['socila-item-icon']}><img src={FacebookIcon}/></div>
            <div className={styles['socila-item-input']}><Input defaultValue={profile?.social_facebook} label="Facebook" setInputValue={setFb} inputClassName={styles.input} className={styles['input-container']} /></div>
          </div>
          <div className={styles['socila-item']}>
            <div className={styles['socila-item-icon']}><img src={YoutubeIcon}/></div>
            <div className={styles['socila-item-input']}><Input defaultValue={profile?.social_youtube} label="Youtube" setInputValue={setYoutube} inputClassName={styles.input} className={styles['input-container']} /></div>
          </div>
          <div className={styles['socila-item']}>
            <div className={styles['socila-item-icon']}><img src={EmailIcon}/></div>
            <div className={styles['socila-item-input']}><Input defaultValue={profile?.social_email} label="Email" setInputValue={setEmail} inputClassName={styles.input} className={styles['input-container']} /></div>
          </div>
          <div className={styles['socila-item']}>
            <div className={styles['socila-item-icon']}><img src={InstagramIcon}/></div>
            <div className={styles['socila-item-input']}><Input defaultValue={profile?.social_instagram} label="Instagram" setInputValue={setInst} inputClassName={styles.input} className={styles['input-container']} /></div>
          </div>
        </div>

      </div>


      <div className={styles['button-container']}>
        <div className={styles.button} onClick={updateProfile}>{ button_text }</div>
      </div>

    </div>
  );
};
