import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPassRestore, restorePasswordData } from '../../http/userAPI';
import { Logo } from '../../ui-kit/assets';
import { ActionButton, Input, PasswordInput } from '../../ui-kit/components';
import { alertDanger, alertSuccess, RESTORE_PASSWORD_ROUTER } from '../../utils/consts';

import styles from './RestorePassword.module.scss';

export const RestorePasswordCreate = () => {

  const { uid } = useParams()

  const [active, setActive] = useState(false)
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handlerRestorePassword = async () => {
   try {
      let data = await restorePasswordData(password, confirm_password, uid)
      alertSuccess(dispatch, data.message)
      navigate('/sign-in')
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }


  const [message, setMessage] = useState('')




  let load_data = async () => {
    let data = await checkPassRestore(uid)
    let error = data.error
    if (error) {
      let message = data.message
      setMessage(message)
    } else {
      setActive(true)
    }
  }

  useEffect(() => {
    load_data()
  }, [])

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content-2']}>

          <div style={{textAlign: 'center', marginBottom: 0}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Восстановление доступа</h1>

          { active && 
            <div className={styles.form}>
              <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setPassword(val)} label="Новый пароль" />
              <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setConfirmPassword(val)} label="Повторите новый пароль" dynamicLabel />
              <div style={{marginTop: 10, width: "100%"}}>
                <ActionButton onClick={handlerRestorePassword} className={styles.button}>Отправить</ActionButton>
              </div>
            </div> 
          }

          { message !== '' && 
            <div className={styles.form}>
              { message }
              <br/>
              <br/>
              <br/>
              <ActionButton type="link" to={RESTORE_PASSWORD_ROUTER} className={styles.button}>Восстановление пароля</ActionButton>
            </div>
          }



        </div>

      </div>
    </>
  );
};
