import { FormDeposit, SelectOption } from '../../components/Deposit';
import styles from './Withdrawal.module.scss';
import { useWindowSize } from '../../hooks';
import { BTC, Logo, UsdIcon } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCourse, refillDeposit, sendCodeWithdrawal, withdrawalStep3 } from '../../http/paymentApi';
import { ActionButton, Input, Select } from '../../ui-kit/components';
import { alertDanger, GOOGLE_AUTH_INFO_ROUTER, GOOGLE_AUTH_ROUTER, TRANSACTIONS_ROUTER } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalThird = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  if (user.google_auth == 0) navigate(GOOGLE_AUTH_INFO_ROUTER)

  const [code, setCode] = useState('')
  const [process, setProcess] = useState(false)
  const [process_email, setProcessEmail] = useState(1)
  const { id } = useParams()
  
  const clickCheckCode = async () => {
    setProcess(true)
    try {
      await withdrawalStep3(id, code)
      navigate(TRANSACTIONS_ROUTER)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
    setProcess(false)
  }

  const sendAgain = async () => {
    setProcessEmail(2)
    await sendCodeWithdrawal(id)
    setProcessEmail(3)
    setTimeout(() => {
      setProcessEmail(1)
    }, 10000)
  }

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 3/3</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывод средств</h1>

          <div className={styles.form}>

            <div className={styles.text}>
              Для подтверждения вывода введите код подтверждения, отправленный на email
            </div>

            { process === false && (
              <>

                <div className={styles.input}>
                    <Input setInputValue={setCode} label="4-цифры" className={styles.input__2fa} />
                </div>

                { process_email == 1 && 
                  <div style={{textAlign: 'center', marginTop: 15, cursor: 'pointer'}} onClick={() => sendAgain()}>Отправить код повторно</div>
                }

                { process_email == 2 && 
                  <div style={{textAlign: 'center', marginTop: 15, cursor: 'pointer'}}>Отправка кода ...</div>
                }

                { process_email == 3 && 
                  <div style={{textAlign: 'center', marginTop: 15, cursor: 'pointer'}}>Код отправлен</div>
                }


                
                <ActionButton onClick={() => clickCheckCode()} className={styles.button}>Далее</ActionButton>
              
              </>
            )}

            { process && (
              <div style={{textAlign: 'center'}}>Сохранение данных ...</div>
            )}

          </div>

        </div>
      </div>
    </> 
  );
};
