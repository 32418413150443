import styles from './Fund.module.scss';
import { HeaderFund, FooterFund, FundProfitability, FundProfitabilityPeriod } from '../../components';
import { useEffect, useState } from 'react';
import { getFundByCpu } from '../../http/fundApi';
import { useParams } from 'react-router-dom';

export const Fund = () => {

  const [ fund, setFund ] = useState({})

  const { cpu } = useParams()

  const load_data = async () => {
    let data = await getFundByCpu(cpu)
    setFund(data)
  }

  useEffect(() => {
    load_data()
  },[cpu])

  return (
    <div className={styles.body}>

      <HeaderFund fund={fund}/>

      <FundProfitability fund={fund}/>

      <FundProfitabilityPeriod fund={fund}/>

      <FooterFund fund={fund}/>

    </div>
  );
};
