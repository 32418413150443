import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { restorePassword } from '../../http/userAPI';
import { Logo, RedCircle } from '../../ui-kit/assets';
import { ActionButton, Input } from '../../ui-kit/components';
import { alertDanger, alertSuccess, GOOGLE_AUTH_ROUTER, MAIN_ROUTER } from '../../utils/consts';
import styles from './RestorePassword.module.scss';

export const RestorePassword = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [step, setStep] = useState(1)

  const restorePasswordHandler = async() => {
    try {
      let data = await restorePassword(email)
      alertSuccess(dispatch, data.message)
      setStep(2)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          <div style={{textAlign: 'center', marginBottom: 0}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          { step == 1 ? (
            <>
              <h1 className={styles.title}>Запросить сброс пароля</h1>
              <div className={styles.form}>
                <div className={styles['circle-content']}><img src={RedCircle} className={styles.circle}/></div>
                <div className={styles['transparent-block']}>В целях безопасности после сброса пароля все операции снятия в вашем аккаунте будут заблокированы на пять дней</div>
                <div className={styles.text}>Для сброса пароля запросите отправку письма на электронную почту своего аккаунта. В этом письме будут описаны дальнейшие действия.</div>
                <div className={styles.text}>В случае вопросов или затруднений обратитесь в службу поддержки support@x3crypto.fund</div>
                <div className={styles.text} style={{width: "100%", marginTop: 30, marginBottom: 20}}>
                  <Input type="text" setInputValue={setEmail} label="Email"/>
                </div>
                <div className={styles.buttons}>
                  <div><ActionButton onClick={() => restorePasswordHandler()}>Отправить</ActionButton></div>
                  <div><Link to={MAIN_ROUTER} className={styles.continue}>Отмена</Link></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h1 className={styles.title}>Успешно</h1>
              <div className={styles.form}>
                <div className={styles.text}>На Вашу почту отправлено письмо с инструкцией по восстановлению пароля </div>
                <div style={{marginTop: 40, marginBottom: 40}}>
                  <div><Link to={MAIN_ROUTER} className={styles.continue}><ActionButton>Понятно</ActionButton></Link></div>
                </div>
              </div>
            </>
          )}

        </div>
      </div>
    </>
  );
};
