import { useWindowSize } from '../../hooks';

import styles from './Investments.module.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { StatFund } from '../../components/Investments/StatFund/StatFund';
import { StatProfit } from '../../components/Investments/StatProfit/StatProfit';
import { ActiveFund } from '../../components/Investments/ActiveFund/ActiveFund';
import { Recomended } from '../../components/Investments/Recomended/Recomended';

export const Investments = () => {

  const { isMobile } = useWindowSize();
  const dispatch = useDispatch()

  const load_data = async () => {

  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <div className={styles.body}>
      <div className={styles.stat}>
        <StatFund/>
        <StatProfit/>
      </div>
      <ActiveFund/>
      <Recomended/>
    </div>
  );
};
