import styles from './StatFundVolume.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getStatFundVolume } from '../../../http/fundApi';
import { useEffect, useState } from 'react';

export const StatFundVolume = (params) => {

  const [ options, setOptions ] = useState(null)

  const load_data = async (fund) => {
    
    if (fund._id) {

      let data = await getStatFundVolume(fund?._id)

      let options_temp = {
          chart: {
              zoomType: 'x',
              backgroundColor: 'transparent'
          },
          title: null,
          subtitle: null,
          xAxis: {
              type: 'datetime'
          },
          yAxis: {
              title: {
                  text: 'USD'
              }
          },
          legend: {
              enabled: false
          },
          plotOptions: {
              area: {
                  fillColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                      stops: [
                          [0, Highcharts.getOptions().colors[0]],
                          [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                      ]
                  },
                  marker: {
                      radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              }
          },

          series: [{
              type: 'area',
              name: 'USD',
              data: data
          }]
      }


      setOptions(options_temp)

    }

  }

  

  useEffect(() => {
    if (params.fund) {
      load_data(params.fund)
    }
  }, [params])


  return (
    <div className={styles.body}>
      <div className={styles.title}>График средств</div>
      <div className={styles['title-underline']}>График отображает ежедневное изменение средств фонда в момент ролловера до исполнения заявок на ввод и вывод</div>
      <div className={styles['chart-block']}>
        { options !== null && 
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          /> }
      </div>
    </div>
  );
};
