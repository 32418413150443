import classNames from 'classnames';
import { useState } from 'react';
import { ControlButton } from '..';
import styles from './ControlButtonMenu.module.scss';

export const ControlButtonMenu = ({ buttons, className, setActiveButton }) => {
  const firstActive = buttons ? buttons[0].value : undefined;
  const [active, setActive] = useState(firstActive);
  const handleButtonClick = value => () => {
    setActive(value);
    setActiveButton(value)
  };
  return (
    <div className={classNames(styles.menu, className)}>
      {buttons?.map(({ name, value }) => (
        <ControlButton
          className={styles.button}
          onClick={handleButtonClick(value)}
          name={name}
          active={active === value}
        />
      ))}
    </div>
  );
};
