import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getNewsAdmin, updateNewsAdmin } from '../../../http/adminApi';
import { ADMIN_NEWS, alertDanger, alertSuccess } from '../../../utils/consts';
import styles from './AdminNews.module.scss';

export const AdminNewsUpdate = () => {

  const { news_id } = useParams()
  
  const [ title, setTitle ] = useState('')
  const [ desc, setDesc ] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const createNewsHandler = async () => {
    try {
      await updateNewsAdmin(news_id,title,desc)
      alertSuccess(dispatch, "Новость успешно изменена")
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  const load_data = async () => {
    const data = await getNewsAdmin(news_id)
    setTitle(data.title)
    setDesc(data.description)
  }
  
  useEffect(() => {
    load_data()
  },[])

  return (
    <div className={styles.body}>
      <Link to={ADMIN_NEWS}><div style={{fontSize: 13, marginBottom: 20,}}>Назад ко всем новостям</div></Link>
      <h1 style={{fontSize: 20}}>Редактирование новости</h1>

      <div style={{marginTop: 10}}>
        <div>Заголовок новости</div>
        <div style={{marginTop: 5}}>
          <input className={styles.input} type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
        </div>
      </div>
      

      <div style={{marginTop: 10, color: "white"}}>
        <div>Описание</div>
        <div style={{marginTop: 5, backgroundColor: "white", color: "black"}}>
          <textarea className={styles.textarea} value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
        </div>
      </div>

      <div style={{marginTop: 10}}>
        <div>
          <input type="button" onClick={() => createNewsHandler()} value="Сохранить" className={styles.button}/>
        </div>
      </div>


    </div>
  );
};
