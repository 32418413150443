import { FormDeposit, SelectOption } from '../../components/Deposit';
import styles from './Deposit.module.scss';
import { useWindowSize } from '../../hooks';
import { BTC, Logo } from '../../ui-kit/assets';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCourse, refillDeposit } from '../../http/paymentApi';
import { ActionButton, Input, Select } from '../../ui-kit/components';
import { useDispatch, useSelector } from 'react-redux';
import { alertDanger, GOOGLE_AUTH_INFO_ROUTER, GOOGLE_AUTH_ROUTER, options_currency } from '../../utils/consts';

export const Deposit = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  
  const user = useSelector((state) => state.user.user)
  if (user.google_auth == 0) navigate(GOOGLE_AUTH_INFO_ROUTER)

  const [ symbol_ob, setSymbolOb ] = useState(options_currency[0])
  const [ symbol, setSymbol ] = useState('BTC')
  const [ amount, setAmount ] = useState('')
  const [ amount_usd, setAmountUsd ] = useState('0')
  const [ amount_usd_real, setAmountUsdReal ] = useState(0)
  const [ course, setCourse ] = useState('')
  const [ courses, setCourses ] = useState({})

  const load_data = async () => {
    
    let data = await getCourse()
    setCourse(data.BTC)
    setCourses(data)

  }

  const setAmountInput = (val) => {
    setAmount(val)
    let amount_usd = val * course
    setAmountUsdReal(amount_usd)
    let itog = Math.round(amount_usd)
    let text = itog == 0 ? itog : ("≈" + itog)
    setAmountUsd(text)
  }


  useEffect(() => {
    load_data()
  },[])

  const clickBtnDeposit = async () => {

      if (symbol === '') {
        alertDanger(dispatch, "Выберите криптовалюту, на которую будете переводить средства")
          return
      }

      if (amount === '') {
        alertDanger(dispatch, "Введите сумму пополнения")
          return
      }

      if (amount_usd_real < 10 && user.id !== "62d3cdd635953cfb70de8ed2") {

        alertDanger(dispatch, "Сумма пополнения должна быть от 10 $")

      } else {

        try {

          let data = await refillDeposit(amount, symbol)
          navigate('/deposit-final/' + data._id)
          
        } catch (e) {

          alertDanger(dispatch, e.response.data.message)

        }

      }
  }

  const changeCurrency = (o) => {
    setSymbolOb(o)
    setSymbol(o.value)
    setCourse(courses[o.value.replace(' ','_')])
  }


  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 1/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Пополнение депозита</h1>

          <div className={styles.revieve}>Вы получите <span className={styles['revieve-amount']}>{ amount_usd }</span> USD на кошелек *</div>
          
          <div className={styles.form}>

            <div className={styles.input} style={{marginBottom: 10}}>
                <Select options={options_currency} onChange={changeCurrency} className={styles.input__wrapper} placeholder={"Выберите валюту пополнения"} selectedOption={symbol_ob}></Select>
            </div>
            
            <div className={styles.input}>
                <Input setInputValue={setAmountInput} type="number" label="Введите сумму" icon={BTC} className={styles.input__wrapper} />
            </div>

            <div className={styles.notification}>* итоговая сумма пополнения зависит от курса {symbol}/USD и будет рассчитана в момент зачисления {symbol} на кошелек с учетом всех комиссий.</div>
            <div className={styles.course}>Курс {symbol}/USD { course }</div>

            <ActionButton onClick={() => clickBtnDeposit()} className={styles.button}>Далее</ActionButton>
          </div>

          <div className={styles.text}>Min сумма пополнения 10 USD</div>

        </div>
      </div>
    </> 
  );
};
