import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { change_password } from '../../http/userAPI';
import { ActionButton, Card, Input, PasswordInput } from '../../ui-kit/components';

import styles from './ChangePassword.module.scss';

export const ChangePassword = () => {

  const navigate = useNavigate()

  const [password_old, setPasswordOld] = useState('')
  const [password_new, setPasswordNew] = useState('')
  const [password_new_confirn, setPasswordNewConfirm] = useState('')

  const handleChangePassword = async () => {
    if (password_old.length >= 5 && password_new.length >= 5 && password_new_confirn.length >= 5) {
      try {
        let data = await change_password(password_old, password_new, password_new_confirn)
        setPasswordOld('')
        setPasswordNew('')
        setPasswordNewConfirm('')
        alert(data.message)
        navigate('/')
      } catch(e) {
        alert(e.response.data.message)
      }
    } else {
      alert("Пароль должен быть больше 5 символов")
    }
  }

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Сменить пароль</h1>
      <Card className={styles.form__wrapper}>

        <div className={styles.form}>
          <Input
            className={styles['form__input-wrapper']}
            inputClassName={styles['form__input']}
            setInputValue={setPasswordOld}
            label="Введите старый пароль"
            type="password"
          />
          <Input
            className={styles['form__input-wrapper']}
            inputClassName={styles['form__input']}
            setInputValue={setPasswordNew}
            label="Введите новый пароль"
            type="password"
          />
          <Input
            className={styles['form__input-wrapper']}
            inputClassName={styles['form__input']}
            setInputValue={setPasswordNewConfirm}
            label="Повторите новый пароль"
            type="password"
          />
          <ActionButton onClick={handleChangePassword} className={styles['form__button']}>Изменить пароль</ActionButton>
        </div>
      </Card>
    </div>
  );
};
