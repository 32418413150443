import { Logo } from '../../ui-kit/assets';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../../ui-kit/components';
import moment from 'moment'

import styles from './ChooseFund.module.scss';
import { INVESTMENTS_ROUTER } from '../../utils/consts';

export const ChooseFundStep3 = () => {

  const navigate = useNavigate()

    
  let min = moment().format('m')
  let sec = moment().format('s')
  
  let min_will = 60 - Number(min) - 1
  let sec_will = 60 - Number(sec)


  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Успешно </h1>

          <h3 className={styles.under_title}>Ваша заявка будет обработана через <span>{ min_will } мин { sec_will } сек</span></h3>


          <div className={styles.form_footer}>
            <ActionButton onClick={() => navigate(INVESTMENTS_ROUTER)} className={styles.button}>Понятно</ActionButton>
          </div>

        </div>
      </div>
    </> 
  );
};
