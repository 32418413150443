import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './Textarea.module.scss';

export const Textarea = ({ className, textareaClassName, placeholder, setTextareaValue, defaultValue }) => {

  const [value, setValue] = useState('')
  
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const setValueData = (e) => {
    let val = e.target.value
    setValue(val)
    setTextareaValue(val)
  }

  return (
    <div className={classNames(className, styles.wrapper)}>
      <textarea
        className={classNames(textareaClassName, styles.textarea)}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValueData(e)}
      />
    </div>
  );
};
