import { $authHost, $host } from './index.js'

export const getActivePayments = async () => {
    const {data} = await $authHost.get('api/payment/get_active_payments')
    return data
}

export const getComission = async () => {
    const {data} = await $host.get('api/payment/get_comission')
    return data
}

export const getGraph = async () => {
    const {data} = await $authHost.get('api/payment/get_graph')
    return data
}


export const getCourse = async () => {
    const {data} = await $host.get('api/payment/get_course')
    return data
}


export const getCurrencyToken = async () => {
    const {data} = await $authHost.get('api/payment/get_currency_token')
    return data
}

export const getUserBalance = async () => {
    const {data} = await $authHost.get('api/payment/get_user_balance')
    return data
}

export const getPlans = async () => {
    const {data} = await $host.get('api/payment/get_plans')
    return data
}

export const getPlan = async (plan_key) => {
    const {data} = await $host.get('api/payment/get_plan?plan_key=' + plan_key)
    return data
}

export const refillDeposit = async (amount, symbol) => {
    const {data} = await $authHost.post('api/payment/refill_deposit',{
        amount, symbol
    })
    return data
}

export const calculateComissionWithdrawal = async (address_recipient, amount, fee = 50, currency = 'BTC') => {
    const {data} = await $authHost.post('api/payment/calculate-comission-withdrawal',{
        address_recipient, amount, fee, currency
    })
    return data
}

export const processWithdrawal = async (token, payment_id) => {
    const {data} = await $authHost.post('api/payment/process_withdrawal',{
        token, payment_id
    })
    return true
}

export const finishProcessWithdrawal = async (token, payment_id, code) => {
    const {data} = await $authHost.post('api/payment/finish_process_withdrawal',{
        token, payment_id, code
    })
    return data
}

export const getPayment = async (payment_id) => {
    const {data} = await $authHost.post('api/payment/get_payment',{
        payment_id
    })
    return data
}

export const getRefill = async (refill_id) => {
    const {data} = await $authHost.post('api/payment/get_refill',{
        refill_id
    })
    return data
}

export const getHistory = async () => {
    const {data} = await $authHost.get('api/payment/get_history')
    return data
}

export const getHistoryFund = async () => {
    const {data} = await $authHost.get('api/payment/get_history_fund')
    return data
}

export const refillConfirm = async (refill_id) => {
    const {data} = await $authHost.post('api/payment/refill_confirm',{
        refill_id
    })
    return data
}

export const withdrawalStep1 = async (amount_usd, wallet, symbol) => {
    const {data} = await $authHost.post('api/payment/withdrawal-step-1',{
        amount_usd, wallet, symbol
    })
    return data
}

export const withdrawalStep2 = async (id, code) => {
    const {data} = await $authHost.post('api/payment/withdrawal-step-2',{
        id, code
    })
    return data
}

export const withdrawalStep3 = async (id, code) => {
    const {data} = await $authHost.post('api/payment/withdrawal-step-3',{
        id, code
    })
    return data
}

export const sendCodeWithdrawal = async (id) => {
    const {data} = await $authHost.post('api/payment/send-code-withdrawal',{
        id
    })
    return data
}

export const getWithdrawal = async (id) => {
    const {data} = await $authHost.post('api/payment/get_withdrawal',{
        id
    })
    return data
}

