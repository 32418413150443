import { useState, useRef, useEffect } from 'react';
import { Link , Navigate} from 'react-router-dom';
import classNames from 'classnames';
import { useClickOutside } from '../../hooks';
import { Card, ColoredText } from '../../ui-kit/components';

import { ReactComponent as ProfileIcon } from './assets/ProfileIcon.svg';
import { ReactComponent as MedalIcon } from './assets/Medal.svg';
import { ReactComponent as ReferalIcon } from './assets/Referal.svg';
import { ReactComponent as KeyIcon } from './assets/Key.svg';
import { ReactComponent as SignOutIcon } from './assets/SignOut.svg';
import { 
  actionSetIsAuth,
  actionSetUserData
} from '../../store/actionCreators/userActionCreator'

import Verified from './assets/Verified.png';
import styles from './LoginButton.module.scss';
import { useSelector, useDispatch } from 'react-redux'
import { ADMIN_SETTING_ROUTER, MAIN_ROUTER } from '../../utils/consts';
import { UserIcon } from '../../ui-kit/assets';

export const LoginButton = ( { logged } ) => {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const profileMenuRef = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log(user)
  }, [user])
  

  const onProfileIconClick = () => setActive(state => !state);

  const onClickOutside = () => setActive(false);

  const logOut = () => {
    
    dispatch(actionSetIsAuth(false))
    dispatch(actionSetUserData({}))

    delete localStorage.token;
    delete localStorage.pass;
    delete localStorage.email;

    Navigate(MAIN_ROUTER)
    
  }

  useClickOutside(onClickOutside, profileMenuRef);

  return logged ? (
    <div className={styles.wrapper} ref={profileMenuRef}>
      <div className={styles.body} onClick={onProfileIconClick}>
        <div className={styles['user-icon-container']}>
          <img src={UserIcon} className={styles['user-icon']}/>
        </div>
      </div>
      {active && (
        <Card className={styles.menu}>
          <p className={styles.menu__header}>{ user.email }</p>
          {user.rool == 'admin' && (
            <>
              <Link className={styles['menu-item']} to='/admin/news' onClick={onClickOutside}>
                <KeyIcon className={styles['menu-item__icon']} />
                Новости
              </Link>
              <Link className={styles['menu-item']} to={ADMIN_SETTING_ROUTER} onClick={onClickOutside}>
                <KeyIcon className={styles['menu-item__icon']} />
                Настройки администратора
              </Link>
            </>
          )}
          <Link className={styles['menu-item']} to='/profile' onClick={onClickOutside}>
            <ProfileIcon className={styles['menu-item__icon']} />
            Настройки профиля
          </Link>
          <div className={styles['menu-separator']} />
          <div
            className={classNames(styles['menu-item'], styles['menu-item--sign-out'])}
            onClick={logOut}
          >
            <SignOutIcon className={styles['menu-item__icon']} />
            Выйти
          </div>
        </Card>
      )}
    </div>
  ) : (
    <Link to='/sign-in' className={styles['body-not-auth']}>
      { false && <ProfileIcon className={styles.icon} /> }
      {!logged && "Войти"}
    </Link>
  );
};
