import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DEPOSIT_ROUTER, MAIN_ROUTER, prettyUsd, WITHDRAWAL_ROUTER, WITHDRAWAL_STEP_1_ROUTER } from '../../../utils/consts';
import Skeleton from '@mui/material/Skeleton';

import styles from './BalanceInfo.module.scss';

export const BalanceInfo = () => {

  const balance = useSelector((state) => state.fund.balance)
  const comission = useSelector((state) => state.fund.comission)
  const balance_wallet = useSelector((state) => state.fund.balance_wallet)
  const balance_profit_now = useSelector((state) => state.fund.balance_profit_now)
  const balance_investment = useSelector((state) => state.fund.balance_investment)

  const [ pr_balance_investment, setPrBalanceInvestment ] = useState(0)
  const [ pr_balance_wallet, setPrBalanceWallet ] = useState(0)
  const [ pr_balance_profit_now, setPrBalanceProfitNow ] = useState(0)

  const [loading, setLoading] = useState(true)

  useEffect(() => {

    if (
      balance !== null &&
      comission !== null &&
      balance_wallet !== null &&
      balance_profit_now !== null &&
      balance_investment !== null 
    ) {
      setLoading(false)
    }

  }, [ balance, comission, balance_wallet, balance_profit_now, balance_investment ])

  return (
    
    <>

      { !loading && (

        <div>
          <div className={styles.title}>Баланс</div>
          <div className={styles.amount}>{ prettyUsd(balance) } USD</div>
          <div className={styles['buttons-container']}>
            <div>
              <Link to={DEPOSIT_ROUTER}><div className={styles['button-deposit']}>Пополнить</div></Link>
            </div>
            <div>
              <Link to={WITHDRAWAL_STEP_1_ROUTER}><div className={styles['button-withdrawal']}>Вывести</div></Link>
            </div>
            <div>
              <div className={styles.block}>
                <div className={styles.comission}>Комиссия сервиса</div>
                <div className={styles['comission-number']}>{ comission }%</div>
                <div className={styles['comission-link']}><Link to={MAIN_ROUTER}>Подробнее</Link></div>
              </div>
            </div>
          </div>

          <div className={styles['flex-icon-container']}>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}>{ pr_balance_investment } %</div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Инвестиции</div>
                <div className={styles.bottom}>{ prettyUsd(balance_investment) } USD</div>
              </div>
            </div>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}>{ pr_balance_profit_now } %</div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Прибыль</div>
                <div className={styles.bottom}>{ prettyUsd(balance_profit_now) } USD</div>
              </div>
            </div>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}>{ pr_balance_wallet } %</div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Кошелек</div>
                <div className={styles.bottom}>{ prettyUsd(balance_wallet) } USD</div>
              </div>
            </div>

          </div>

        </div>
      
      )}





      
      { loading && (
        
        <div>
          <div className={styles.title}>Баланс</div>
          <div className={styles.amount}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"30%"} height={20} /></div>
          <div className={styles['buttons-container']}>
            <div>
              <Link to={DEPOSIT_ROUTER}><div className={styles['button-deposit']}>Пополнить</div></Link>
            </div>
            <div>
              <Link to={WITHDRAWAL_STEP_1_ROUTER}><div className={styles['button-withdrawal']}>Вывести</div></Link>
            </div>
            <div>
              <div className={styles.block}>
                <div className={styles.comission}>Комиссия сервиса</div>
                <div className={styles['comission-number']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"100%"} height={20} /></div>
                <div className={styles['comission-link']}><Link to={MAIN_ROUTER}>Подробнее</Link></div>
              </div>
            </div>
          </div>

          <div className={styles['flex-icon-container']}>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 15, marginTop: -1 }} variant="circle" width={"100%"} height={25} /></div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Инвестиции</div>
                <div className={styles.bottom}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"100%"} height={20} /></div>
              </div>
            </div>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 15, marginTop: -1 }} variant="circle" width={"100%"} height={25} /></div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Прибыль</div>
                <div className={styles.bottom}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"100%"} height={20} /></div>
              </div>
            </div>

            <div className={styles['flex-icon-item']}>
              <div className={styles['icon-circle']}>
                <div className={styles['icon-circle-number']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 15, marginTop: -1 }} variant="circle" width={"100%"} height={25} /></div>
              </div>
              <div className={styles.left}>
                <div className={styles.top}>Кошелек</div>
                <div className={styles.bottom}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"100%"} height={20} /></div>
              </div>
            </div>

          </div>

        </div>
      
      )}
      
    </>

  );
};
