import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Nav, LoginButton, BurgerMenu } from '..';
import { useClickOutside, useWindowSize } from '../../hooks';
import { Close, Logo, NotificationIcon, WalletIcon } from '../../ui-kit/assets';
import { DEPOSIT_ROUTER, NOTIFICATION_ROUTER, MAIN_ROUTER, prettyUsd, TRANSACTIONS_ROUTER, WITHDRAWAL_STEP_1_ROUTER } from '../../utils/consts';
import { createBrowserHistory } from "history";
import styles from './Header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getBalanceInvestment, getBalanceProfitNow, getBalanceWallet, getComissionSummNotPaid, getFundsUser, getStatUser, getTotalBalance, getTotalComission } from '../../http/fundApi';
import { actionSetBalance, actionStatUser, actionSetBalanceInvestment, actionSetBalanceProfitNow, actionSetBalanceWallet, actionSetComission, actionSetComissionSummNotPayment, actionSetFunds } from '../../store/actionCreators/fundActionCreator';
import AlertSuccess from '../Alert/AlertSuccess/AlertSuccess';
import AlertDanger from '../Alert/AlertDanger/AlertDanger';
import { NavNotLog } from '../NavNotLog/NavNotLog';
import { Card } from '../../ui-kit/components';
import { actionSetAmountNotification, actionSetBrowserHistory, actionSetFirstLoadNotification, actionSetNotifications, actionSetNotificationStatus } from '../../store/actionCreators/siteActionCreator';
import { getPathnameFromLocation, pagesToShowCloseButton } from '../../utils/modals'
import { getNotifications } from '../../http/siteApi';


export const Header = ({ logged = true }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { isMobile } = useWindowSize();
  const location = useLocation();
  let pathname = getPathnameFromLocation(location)
  const isOnLoginStage = pagesToShowCloseButton[pathname];

  const browser_history = useSelector((state) => state.site.browser_history)
  const notification_amount = useSelector((state) => state.site.notification_amount)

  useEffect(() => {
    if (!pagesToShowCloseButton[pathname]) {
      dispatch(actionSetBrowserHistory(location.pathname))
    }
  },[location])


  const onCloseClick = async () => {
    
    dispatch(actionSetNotificationStatus(1))

    if (window.history.state && window.history.state.idx > 0) {
      navigate(browser_history[0])
    } else {
      navigate(MAIN_ROUTER)
    }

    let data = await getNotifications()
    dispatch(actionSetNotifications(data))
    dispatch(actionSetAmountNotification(data.length))

  };
  
  const load_data = async() => {
    if (logged) {

      let data 

      data = await getTotalBalance()
      dispatch(actionSetBalance(data.balance))

      data = await getTotalComission()
      dispatch(actionSetComission(data.comission))

      data = await getFundsUser()
      dispatch(actionSetFunds(data))
      
      data = await getBalanceWallet()
      dispatch(actionSetBalanceWallet(data.amount))

      data = await getBalanceInvestment()
      dispatch(actionSetBalanceInvestment(data.amount))

      data = await getBalanceProfitNow()
      dispatch(actionSetBalanceProfitNow(data.amount))

      data = await getComissionSummNotPaid()
      dispatch(actionSetComissionSummNotPayment(data.amount))

      data = await getStatUser()
      dispatch(actionStatUser(data)) 

      
      data = await getNotifications()
      dispatch(actionSetNotifications(data))
      dispatch(actionSetAmountNotification(data.length))
      if (data.length > 0) {
        navigate(NOTIFICATION_ROUTER)
      }


    }
  }


  const startProcessNotificationAsync = async () => {

    setInterval(async() => {
      let data = await getNotifications()
      dispatch(actionSetAmountNotification(data.length))
    }, 5000)
   
  }
  useEffect(() => {

    startProcessNotificationAsync()

  },[])

  
  

  const balance = useSelector((state) => state.fund.balance)
  const alert_success = useSelector((state) => state.site.alert_success)
  const alert_success_text = useSelector((state) => state.site.alert_success_text)
  const alert_danger = useSelector((state) => state.site.alert_danger)
  const alert_danger_text = useSelector((state) => state.site.alert_danger_text) 
  const notifications = useSelector((state) => state.site.notifications) 
  const first_load_notification = useSelector((state) => state.site.first_load_notification) 


  const [ deposit_menu, setDepositMenu ] = useState(false)
  const depositMenu = () => {
    setDepositMenu(!deposit_menu)
  }
  const menuDepositRef = useRef(null);
  const onClickOutside = () => setDepositMenu(false);
  useClickOutside(onClickOutside, menuDepositRef);

  useEffect(() => {
    load_data()
  }, [logged])



  return (
    <header className={styles.header}>

      <div className={styles.logo}>
        {!isOnLoginStage && (
          <Link to={MAIN_ROUTER}>
            <img className={styles['logo-img']} src={Logo}/>
          </Link> 
        )}
      </div>

      {!isOnLoginStage ? (
        !isMobile ? (
          <>
            { logged && <Nav /> }
            { !logged && <NavNotLog /> }

            { logged && (
              <>
                <div className={styles.menus} ref={menuDepositRef}>
                  
                  <div className={styles['header-wallet']}>
                    <div><Link to={TRANSACTIONS_ROUTER}><img src={WalletIcon} className={styles['wallet-icon']}/></Link>&nbsp;&nbsp;&nbsp;</div>
                    <div><Link to={TRANSACTIONS_ROUTER} style={{fontWeight: 800, fontSize: 13}}>{ prettyUsd(balance) } USD</Link>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div><div onClick={depositMenu} className={styles['header-plus']}>+</div></div>
                  </div>

                  {deposit_menu && (
                    <Card className={styles.menu}>
                      <Link className={styles['menu-item']} to={ DEPOSIT_ROUTER } onClick={onClickOutside}>
                        Пополнить баланс
                      </Link>
                      <div className={styles['menu-separator']} />
                      <Link className={styles['menu-item']} to={ WITHDRAWAL_STEP_1_ROUTER } onClick={onClickOutside}>
                        Вывод средств
                      </Link> 
                    </Card>
                  ) }

                </div>
               </>
            )}

            { logged && (
              <>
                <div className={styles.menus}>

                  { logged && (
                    <div className={styles['header-notification']}>
                      <div><img src={NotificationIcon} className={styles['notification-icon']}/>&nbsp;&nbsp;&nbsp;</div>
                      <div><Link to={NOTIFICATION_ROUTER}><div className={styles['header-notification-number']}>{ notification_amount }</div></Link></div>
                    </div>
                  ) }

                </div>
              </>
            ) }

            <div className={styles.menus}>
              <LoginButton logged={logged} />
            </div>
          </>
        ) : (
          <BurgerMenu logged={logged} />
        )
      ) : (
        <div className={styles.close} onClick={onCloseClick}>
          <Close />
        </div>
      )}

      { alert_success && <AlertSuccess text={ alert_success_text }/>} 
        
      { alert_danger && <AlertDanger text={ alert_danger_text }/>} 


    </header>
  );
};
