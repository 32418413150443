import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButton } from '../../ui-kit/components';
import {QRCodeCanvas} from 'qrcode.react';

import styles from './Deposit.module.scss';
import { alertDanger, alertSuccess, MAIN_ROUTER } from '../../utils/consts';
import { Logo } from '../../ui-kit/assets';
import { getRefill, refillConfirm } from '../../http/paymentApi';

export const DepositFinal = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  
  const [ refill, setRefill ] = useState({})
  const [ token, setToken ] = useState('')
  const { refill_id } = useParams()

  const load_data = async () => {

    let data = await getRefill(refill_id)
    console.log(data)
    setRefill(data)

  }

  useEffect(() => {
    load_data()
  },[])

  useEffect(() => {
    if (refill.token_to) {
      setToken(refill.token_to)
    }
  },[refill])



  const clickBtnDepositFinal = async () => {
    try {
      await refillConfirm(refill_id)
      alertSuccess(dispatch,'Вы успешно подтвердили перевод')
      navigate(MAIN_ROUTER)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 2/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Пополнение депозита</h1>
        

          <div className={styles.revieve}>Отправьте <span className={styles['revieve-amount']}>{ refill?.amount }</span> { refill?.currency_symbol } на адрес <span className={styles['revieve-amount']}>{ token }</span> и подтвердите перевод. После зачисления { refill?.currency_symbol } система обновит ваш баланс.</div>

          <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
            { token !== '' && <QRCodeCanvas size={158} value={token} /> }
          </div>

          <div className={styles.revieve}>{ refill?.currency_symbol } adress</div>
          <div style={{textAlign: 'center'}}>{token}</div>

          <ActionButton onClick={() => clickBtnDepositFinal()} className={styles.button}>Подтвердить</ActionButton>
         
        </div>
      </div>
    </>
  );
};
