import classNames from 'classnames';
import { Accordeon, ActionButton, ColoredText, HollowButton } from '../../../ui-kit/components';
import styles from './History.module.scss';
import { useSelector } from 'react-redux';
import { crypto_icons } from '../../../utils/symbol';
import moment from 'moment'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


export const History = () => {

  const payments = useSelector((state) => state.payment.active_payments)


  return (
    <Accordeon title='Активные подписки' isOpen={true}>

      {payments.length == 0 &&
        <div className={styles['accordeon-item']}>
          <div className={styles['accordeon-item__text-not-subscribe']}>У Вас нет действующих подписок. Хотите оформить?</div>
          <ActionButton className={styles['input-box__button']} type='link' to='/deposit'>
            Оформить подписку
          </ActionButton>
        </div>
      }

      {payments.map(item => {

        return (
          <div className={styles['accordeon-item']}>

            <div className={ styles['accordeon-item__plan-name'] }>
              <ColoredText color='green'>{ item.plan_name }</ColoredText>
            </div>

            <div className={ styles['accordeon-item__amount-process'] }>
              Сумма пополнения: <span>{ item.process_amount } USD</span>
            </div>

            <div className={styles['accordeon-item__date-and-time']}>
              Период подписки: { item.period_start } - { item.period_finish }
            </div>
            
            <div className={styles['accordeon-item__title']}>Текущая финансовая информация</div>

            <div className={styles['accordeon-item__finance-block']}>
              <div className={styles['accordeon-item__finance-block__item']}>Баланс на текущий момент: { item.user_balance } $</div>
              <div className={styles['accordeon-item__finance-block__item']}>Прибыль (без учета комиссии): { item.profit } $</div>
              <div className={styles['accordeon-item__finance-block__item']}>Прибыль (с учетом комиссии): { item.profit_final } $</div>
            </div>

            <div>

              <div>
                  { item.can_withdrawal ? (
                    <div className={styles['withdrawalBlockPayment']}>
                      <div className={styles['buttonPlace']}>
                        <Link to={"/withdrawal/" + item._id}>
                          <button className={styles['buttonWithdrawal']}>Заказать вывод</button>
                        </Link>
                      </div>
                      <div>Вывод средств будет доступен до { item.date_withdrawal_finish_print }</div>
                      <div>Если не вывести до { item.date_withdrawal_finish_print }, деньги останутся в системе до следующего окна вывода.</div>
                    </div>
                  ) : (
                    <>
                      <div className={styles['withdrawalBlockPaymentOut']}>Вывод средств будет доступен с { item.date_withdrawal_start_print } - по { item.date_withdrawal_finish_print }</div>
                    </>
                  ) } 
              </div>

            </div>

          </div>
        );
      })}
    </Accordeon>
  );
};
