import { 
    ACTION_SET_BALANCE,
    ACTION_SET_COMISSION,
    ACTION_SET_FUNDS,
    ACTION_SET_BALANCE_WALLET,
    ACTION_SET_PROFIT_NOW,
    ACTION_SET_BALANCE_INVESTMENT,
    ACTION_SET_COMISSION_SUMM_NOT_PAYMENT,
    ACTION_SET_STAT_USER,
} from '../actions/FundAction'


export const actionSetComissionSummNotPayment = (payload) => {
    return {
        type: ACTION_SET_COMISSION_SUMM_NOT_PAYMENT,
        payload: payload
    }
}

export const actionSetBalanceWallet = (payload) => {
    return {
        type: ACTION_SET_BALANCE_WALLET,
        payload: payload
    }
}

export const actionSetBalanceInvestment = (payload) => {
    return {
        type: ACTION_SET_BALANCE_INVESTMENT,
        payload: payload
    }
}

export const actionSetBalanceProfitNow = (payload) => {
    return {
        type: ACTION_SET_PROFIT_NOW,
        payload: payload
    }
}

export const actionStatUser = (payload) => {
    return {
        type: ACTION_SET_STAT_USER,
        payload: payload
    }
}


export const actionSetBalance = (payload) => {
    return {
        type: ACTION_SET_BALANCE,
        payload: payload
    }
}

export const actionSetComission = (payload) => {
    return {
        type: ACTION_SET_COMISSION,
        payload: payload
    }
}

export const actionSetFunds = (payload) => {
    return {
        type: ACTION_SET_FUNDS,
        payload: payload
    }
}