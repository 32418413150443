import { useState, useEffect } from 'react';
import { Capital, CapitalMyself, Duration, Start, TradingResult } from '../../../ui-kit/assets';
import { Link, NavLink, useParams } from 'react-router-dom';
import styles from './FooterFund.module.scss';
import { getFundByCpu } from '../../../http/fundApi';
import classNames from 'classnames';

export const FooterFund = (params) => {

  const [ fund, setFund ] = useState({})
  const { cpu } = useParams()

  useEffect(() => {
    setFund(params.fund)
  }, [params])


  

  return (
    <div>
      <div className={styles.body}>
        <div classNmae={styles['body-item']}><div className={styles['body-item-inner']}>Вознаграждение управляющего <span className={styles['item-itog']}>{fund?.comission_profit}%</span></div></div>
        <div classNmae={styles['body-item']}><div className={styles['body-item-inner']}>Комиссия сервиса <span className={styles['item-itog']}>до 5%</span></div></div>
        <div classNmae={styles['body-item']}><div className={styles['body-item-inner']}>Период ролловера <span className={styles['item-itog']}>60 мин</span></div></div>
      </div>
      <div className={styles['button-container']}>
        <Link to={'/choose-fund-step-1/' + cpu}>
          <div className={styles.button}>Инвестировать</div>
        </Link>
      </div>
    </div>
  );
};
