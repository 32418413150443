import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllBalances, getComission, getFundData, getSettings, getUsers, updateSetting } from '../../../http/adminApi';
import { Input, Select } from '../../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../../utils/consts';
import styles from './AdminSetting.module.scss';

export const AdminSetting = () => {

  let [setting_balance, setSettingBalance] = useState(null)
  let [comission, setComission] = useState(null)
  const [funds, setFunds] = useState([])
  const [credit_summ, setCreditSumm] = useState("")
  const [users, setUsers] = useState([])
  const [balances, setBalances] = useState([])
  const [ total_amount_comission, setTotalAmountComission ] = useState(0)

  const dispatch = useDispatch()
  

  const option_setting = [
    {
      name: 'Включен',
      value: 1,
    },
    {
      name: 'Выключен',
      value: 0,
    }
  ]

  const _asyncLoad = async () => {
    let data = await getSettings()
    let temp_setting_balance = data.filter((i) => i.key == 'parser_balance')[0]
    setSettingBalance(option_setting.filter((i) => i.value == temp_setting_balance.value)[0])

    setCreditSumm(data.filter((i) => i.key == 'credit_summ_fund')[0].value)

    let temp_comission = await getComission()
    setComission(temp_comission)
    console.log(temp_comission)

    data = await getFundData()
    setFunds(data)
    console.log(data)

    data = await getUsers()
    setUsers(data)

    if (data.length > 0) {
      let total_amount_comission_temp = 0
      data.map((item) => {
        total_amount_comission_temp += item.comission_to_pay
      })
      setTotalAmountComission(total_amount_comission_temp)
    }

    data = await getAllBalances()
    setBalances(data)
  }

  const updateSettingParserBalance = async (ob) => {
    try {
      let data = await updateSetting('parser_balance', ob.value)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  const updateSettingCreditSumm = async (val) => {
    if (val == "") val = 0
    try {
      let data = await updateSetting('credit_summ_fund', val)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  
  useEffect(() => {
    _asyncLoad()
  }, [])
  



  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Настройки</h1>

      <table className={styles.table_setting}>

        <tr>
          <td>Парсер баланса</td>
          <td><Select onChange={updateSettingParserBalance} selectedOption={setting_balance} options={option_setting} placeholder='Парсер баланса' /></td>
        </tr>

        { credit_summ !== "" && (
          <tr>
            <td>Временно вывели из фонда, $</td>
            <td><Input type="number" onBlur={updateSettingCreditSumm} defaultValue={credit_summ}/></td>
          </tr>
        )}

      </table>

      


      <h1 className={styles.title} style={{marginTop: 40}}>Текущие финансовые данные</h1>

      <div>
        { funds.length === 0 && <div>Идет загрузка данных ...</div>}
        { funds.map((item) => {
          return (
            <div>
              <div className={styles.fund_title}>{ item.data.name }</div>
              <table className={styles.table_stat_fund}>
                <tr>
                  <td>Баланс пользователей в фонде</td>
                  <td>Баланс фонда на кошельке</td>
                  <td>Баланс фонда по открытым позициям</td>
                  <td>Временно вывели из фонда *</td>
                  <td>Дельта по фонду</td>
                  <td style={{backgroundColor: "rgb(240,240,240)"}}></td>
                  <td>Баланс пользователей на кошельке</td>
                  <td>Реальный баланс на кошельке</td>
                  <td>Дельта по кошельку</td>
                </tr>

                <tr>
                  <td>{ item.user_amount_fund }$</td>
                  <td>{ item.balance_fund_wallet }$</td>
                  <td>{ item.balance_fund_active }$</td>
                  <td>{ item.credit_summ_fund }$</td>
                  <td>{ item.delta_fund }$</td>
                  <td style={{backgroundColor: "rgb(240,240,240)"}}></td>
                  <td>{ item.user_amount_wallet }$</td>
                  <td>{ item.balance_wallet }$</td>
                  <td>{ item.delta_wallet }$</td>
                </tr>

              </table>

              <div style={{marginTop: 30}}> 
                <div>* -Временно вывели из фонда - эта сумма прибавляется к реальному балансу фонда.</div>
              </div>

            </div>
          )
        })}
      </div>




      <h1 className={styles.title} style={{marginTop: 40}}>Текущие пользователи</h1>

      <div>

        { users.length === 0 && <div>Идет загрузка данных ...</div>}

        { users.length > 0 && (

          <table className={styles.table_stat_fund}>

            <tr>
              <td>Email</td>
              <td>Баланс на кошельке</td>
              <td>Инвестированная сумма</td>
              <td>Сумма сейчас</td>
              <td>Сумма к погашению</td>
              <td>Комисиия сейчас</td>
            </tr>
          
            { users.map((item, i) => {

              return (
                <tr key={"row_" + i}>
                  <td>{ item.email }</td>
                  <td>{ item.amount_usd }$</td>
                  <td>{ item.amount_investment }$</td>
                  <td>{ item.amount_investment_now }$</td>
                  <td>{ item.comission_to_pay }$</td>
                  <td>{ item.comission }%</td>
                </tr>
              )
            })}

          </table>

        )}
      
      </div>


      { total_amount_comission > 0 && <div style={{marginTop: 15}}>Суммарная комиссия к погашению: { total_amount_comission } $</div> }




      <h1 className={styles.title} style={{marginTop: 40}}>Все балансы</h1>

      <div>

        { balances.length === 0 && <div>Идет загрузка данных ...</div>}

        { balances.length > 0 && (

          <table className={styles.table_stat_fund}>

            <tr>
              <td>balance_id</td>
              <td>Дата</td>
              <td>Баланс в balance</td>
              <td>Баланс в user balance</td>
              <td></td>
            </tr>
          
            { balances.map((item) => {
              return (
                <tr>
                  <td>{ item.balance_id }</td>
                  <td>{ item.created_on }</td>
                  <td>{ item.balance_amount }$</td>
                  <td>{ item.user_balance_amount }$</td>
                  <td><span className='fa fa-times'></span></td>
                </tr>
              )
            })}

          </table>

        )}
      
      </div>


    </div>
  );
};
