import { Link, NavLink } from 'react-router-dom';
import { PROFILE_ROUTER, PROFILE_SECURITY_ROUTER } from '../../../utils/consts';
import classNames from 'classnames';
import styles from './HeaderProfile.module.scss';

export const HeaderProfile = () => {
  
  const navLinks = [
    {
      text: "Персональная информация",
      to: PROFILE_ROUTER,
    },
    {
      text: "Безопасность",
      to: PROFILE_SECURITY_ROUTER,
    }
  ]

  return (
    <div className={styles.body}>

      <div className={styles.title}>Профиль</div>

      <nav className={styles['body-links']}>
        {navLinks.map(({ to, text }) => (
          <NavLink className={styles.item} to={to}>
            {({ isActive }) => (
              <>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span
                    className={classNames(styles.item__text, {
                      [styles['item--active']]: isActive,
                    })}
                  >
                    {text}
                  </span>
                </div>
              </>
            )}
          </NavLink>
        ))}
      </nav>

    </div>
  );
};
