import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../ui-kit/components';
import { Burger } from '../../ui-kit/assets';
import { ReactComponent as ProfileIcon } from './assets/ProfileIcon.svg';

import styles from './BurgerMenu.module.scss';
import { useClickOutside } from '../../hooks';
import { DASHBOARD_ROUTER, FUNDS_ROUTER, INVESTMENTS_ROUTER, PROFILE_ROUTER, SIGNIN_ROUTER, TRANSACTIONS_ROUTER } from '../../utils/consts';
import { useSelector } from 'react-redux';

export const BurgerMenu = ({ logged }) => {

  const mainMenuRef = useRef(null);
  const [active, setActive] = useState(false);
  const onBurgerClick = () => setActive(active => !active);
  const onClickOutside = () => setActive(false);

  useClickOutside(onClickOutside, mainMenuRef);

  const isAuth = useSelector((state) => state.user.isAuth)

  return (

    <div className={styles.wrapper} ref={mainMenuRef}>
      <div className={styles.body} onClick={onBurgerClick}>
        <Burger style={{cursor: 'pointer'}} />
      </div>
      {active && (
        <Card className={styles.menu}>
          { isAuth ? (
            <>
            
              <Link className={styles['menu-item']} to={ DASHBOARD_ROUTER } onClick={onClickOutside}>
                Рабочий стол
              </Link>
              <div className={styles['menu-separator']} />

              <Link className={styles['menu-item']} to={ INVESTMENTS_ROUTER } onClick={onClickOutside}>
                Инвестиции
              </Link>
              <div className={styles['menu-separator']} />
              
              <Link className={styles['menu-item']} to={ TRANSACTIONS_ROUTER } onClick={onClickOutside}>
                Транзакции
              </Link>

              <div className={styles['menu-separator']} />
              
              <Link className={styles['menu-item']} to={ PROFILE_ROUTER } onClick={onClickOutside}>
                Настройки профиля
              </Link>
            
            </> 
          ) : (
            <>
              <Link className={styles['menu-item']} to={ SIGNIN_ROUTER } onClick={onClickOutside}>
                Рабочий стол
              </Link>
              <div className={styles['menu-separator']} />

              <Link className={styles['menu-item']} to={ SIGNIN_ROUTER } onClick={onClickOutside}>
                Инвестиции
              </Link>
              <div className={styles['menu-separator']} />
              
              <Link className={styles['menu-item']} to={ SIGNIN_ROUTER } onClick={onClickOutside}>
                Транзакции
              </Link>

              <div className={styles['menu-separator-enter']} />

              <Link className={styles['menu-item']} to={ SIGNIN_ROUTER } onClick={onClickOutside}>
                Войти
              </Link>

            </>
          ) }
        </Card>
      )}
    </div>
  );
};
