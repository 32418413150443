import {
  MainPage,
  SignIn,
  SignUp,
  RestorePassword,
  RestoreEmailSent,
  Fnance,
  History,
  Support,
  ChangePassword,
  Deposit,
  DepositFinal,
  AdminSetting,
  GoogleAuth,
  GoogleAuthInfo,
  Dashboard,
  Investments,
  Transactions,
  Fund,
  WithdrawalFirst,
  WithdrawalSecond,
  WithdrawalThird,
  TransactionsFund,
  FundInvestments,
  FundTrading,
  ChooseFundStep1,
  ChooseFundStep2,
  ChooseFundStep3,
  WithdrawalFundStep1,
  WithdrawalFundStep2,
  WithdrawalFundStep3,
  News,
  Google2FAReset,
} from '../../pages';

import { 
  AdminNews, 
  AdminNewsUpdate, 
  AdminNewsCreate, 
  AdminWithdrawal 
} from '../../pages/Admin';

import { Funds } from '../../pages/Funds';
import { Profile, ProfileSecurity } from '../../pages/Profile';
import { Notifications } from '../../pages/Notifications/Notifications';
import {
  FINANCE_ROUTER,
  DEPOSIT_ROUTER,
  HISTORY_ROUTER,
  MAIN_ROUTER,
  SIGNIN_ROUTER,
  SIGN_UP_ROUTER,
  SUPPORT_ROUTER,
  DEPOSIT_FINAL_ROUTER,
  CHANGE_PASSWORD_ROUTER,
  WITHDRAWAL_STEP_1_ROUTER,
  WITHDRAWAL_STEP_2_ROUTER,
  WITHDRAWAL_STEP_3_ROUTER,
  GOOGLE_AUTH_ROUTER,
  GOOGLE_AUTH_INFO_ROUTER,
  DASHBOARD_ROUTER,
  INVESTMENTS_ROUTER,
  TRANSACTIONS_ROUTER,
  TRANSACTIONS_FUND_ROUTER,
  FUND_ROUTER,
  FUND_INVESTMENTS_ROUTER,
  FUND_TRADING_ROUTER,
  FUNDS_ROUTER,
  PROFILE_ROUTER,
  PROFILE_SECURITY_ROUTER,
  CHOOSE_FUND_STEP_1_ROUTER,
  CHOOSE_FUND_STEP_2_ROUTER,
  CHOOSE_FUND_STEP_3_ROUTER,
  WITHDRAWAL_FUND_STEP_1_ROUTER,
  WITHDRAWAL_FUND_STEP_2_ROUTER,
  WITHDRAWAL_FUND_STEP_3_ROUTER,
  NEWS_ROUTER,
  NOTIFICATION_ROUTER,
  RESTORE_PASSWORD_ROUTER,
  RESTORE_PASSWORD_CREATE_ROUTER,

  GOOGLE_2FA_RESET,

  ADMIN_SETTING_ROUTER,
  ADMIN_WITHDRAWAL_ROUTER,
  ADMIN_NEWS,
  ADMIN_NEWS_UPDATE,
  ADMIN_NEWS_CREATE,

} from '../../utils/consts'
import { RestorePasswordCreate } from '../../pages/RestorePassword/RestorePasswordCreate';

export const authRoutes = [
  {
    path: NOTIFICATION_ROUTER,
    name: 'notifications',
    element: <Notifications/>,
  },
  {
    path: PROFILE_ROUTER,
    name: 'profile',
    element: <Profile/>,
  },
  {
    path: PROFILE_SECURITY_ROUTER,
    name: 'profile-security',
    element: <ProfileSecurity/>,
  },
  {
    path: WITHDRAWAL_FUND_STEP_1_ROUTER,
    name: 'withdrawal-fund-step-1',
    element: <WithdrawalFundStep1/>,
  },
  {
    path: WITHDRAWAL_FUND_STEP_2_ROUTER,
    name: 'withdrawal-fund-step-1',
    element: <WithdrawalFundStep2/>,
  },
  {
    path: WITHDRAWAL_FUND_STEP_3_ROUTER,
    name: 'withdrawal-fund-step-1',
    element: <WithdrawalFundStep3/>,
  },
  {
    path: CHOOSE_FUND_STEP_1_ROUTER,
    name: 'choose-fund-step-1',
    element: <ChooseFundStep1/>,
  },
  {
    path: CHOOSE_FUND_STEP_2_ROUTER,
    name: 'choose-fund-step-2',
    element: <ChooseFundStep2/>,
  },
  {
    path: CHOOSE_FUND_STEP_3_ROUTER,
    name: 'choose-fund-step-3',
    element: <ChooseFundStep3/>,
  },
  {
    path: DASHBOARD_ROUTER,
    name: 'dashboard-router',
    element: <Dashboard/>,
  },
  {
    path: INVESTMENTS_ROUTER,
    name: 'investments-router',
    element: <Investments/>,
  },
  {
    path: TRANSACTIONS_ROUTER,
    name: 'transactions-router',
    element: <Transactions/>,
  },
  {
    path: TRANSACTIONS_FUND_ROUTER,
    name: 'transactions-fund-router',
    element: <TransactionsFund/>,
  },
  {
    path: ADMIN_WITHDRAWAL_ROUTER,
    name: 'admin-withdrawal',
    element: <AdminWithdrawal/>,
  },
  {
    path: ADMIN_NEWS,
    name: 'admin-news',
    element: <AdminNews/>,
  },
  {
    path: ADMIN_NEWS_UPDATE,
    name: 'admin-news-update',
    element: <AdminNewsUpdate/>,
  },
  {
    path: ADMIN_NEWS_CREATE,
    name: 'admin-news-create',
    element: <AdminNewsCreate/>,
  },
  {
    path: ADMIN_SETTING_ROUTER,
    name: 'admin-setting',
    element: <AdminSetting />,
  },
  {
    path: WITHDRAWAL_STEP_1_ROUTER,
    name: 'withdrawal-first',
    element: <WithdrawalFirst />,
  },
  {
    path: WITHDRAWAL_STEP_2_ROUTER,
    name: 'withdrawal-second',
    element: <WithdrawalSecond />,
  },
  {
    path: WITHDRAWAL_STEP_3_ROUTER,
    name: 'withdrawal-third',
    element: <WithdrawalThird />,
  },
  {
    path: FINANCE_ROUTER,
    name: 'finance',
    element: <Fnance />,
  },
  {
    path: HISTORY_ROUTER,
    name: 'history',
    element: <History />,
  },
  {
    path: DEPOSIT_ROUTER,
    name: 'deposit',
    element: <Deposit />,
  },
  {
    path: DEPOSIT_FINAL_ROUTER,
    name: 'deposit-final',
    element: <DepositFinal />,
  },
  {
    path: CHANGE_PASSWORD_ROUTER,
    name: 'change-password',
    element: <ChangePassword />,
  },
  {
    path: GOOGLE_AUTH_ROUTER,
    name: 'google-auth',
    element: <GoogleAuth />,
  },
  {
    path: GOOGLE_AUTH_INFO_ROUTER,
    name: 'google-auth-info',
    element: <GoogleAuthInfo />,
  },
  {
    path: FUND_ROUTER,
    name: 'fund',
    element: <Fund />
  },
  {
    path: NEWS_ROUTER,
    name: 'news',
    element: <News />
  },
  {
    path: FUND_INVESTMENTS_ROUTER,
    name: 'fund',
    element: <FundInvestments />,
  },
  {
    path: FUND_TRADING_ROUTER,
    name: 'fund',
    element: <FundTrading />,
  },
  {
    path: FUNDS_ROUTER,
    name: 'funds',
    element: <Funds />,
  },
  {
    path: MAIN_ROUTER,
    name: 'main-page',
    element: <MainPage />,
  },
]

export const publicRoutes = [
  {
    path: GOOGLE_2FA_RESET,
    name: 'google-2fa-reset',
    element: <Google2FAReset/>,
  },
  {
    path: SIGNIN_ROUTER,
    name: 'sign-in',
    element: <SignIn />,
  },
  {
    path: SIGN_UP_ROUTER,
    name: 'sign-up',
    element: <SignUp />,
  },
  {
    path: SUPPORT_ROUTER,
    name: 'support',
    element: <Support />,
  },
  {
    path: RESTORE_PASSWORD_ROUTER,
    name: 'restore_password',
    element: <RestorePassword />,
  },
  {
    path: RESTORE_PASSWORD_CREATE_ROUTER,
    name: 'restore_password_create',
    element: <RestorePasswordCreate />,
  },
];
