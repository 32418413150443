import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Nav.module.scss';
import { DASHBOARD_ROUTER, INVESTMENTS_ROUTER, TRANSACTIONS_ROUTER } from '../../utils/consts';
import { DashboardIcon, Logo, TransactionIcon, UsdIcon } from '../../ui-kit/assets';

export const Nav = () => {

  const navLinks = [
    {
      text: "Рабочий стол",
      to: DASHBOARD_ROUTER,
      icon: DashboardIcon
    },
    {
      text: "Инвестиции",
      to: INVESTMENTS_ROUTER,
      icon: UsdIcon
    },
    {
      text: "Транзакции",
      to: TRANSACTIONS_ROUTER,
      icon: TransactionIcon
    },
  ]

  return (
    <nav className={styles.body}>
      {navLinks.map(({ to, text, icon }) => (
        <NavLink className={styles.item} to={to}>
          {({ isActive }) => (
            <>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img className={styles.icon} src={icon}/>
                <span
                  className={classNames(styles.item__text, {
                    [styles['item--active']]: isActive,
                  })}
                >
                  {text}
                </span>
              </div>
            </>
          )}
        </NavLink>
      ))}
    </nav>
  );
};
