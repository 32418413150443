import {
    ACTION_SET_ALERT_SUCCESS,
    ACTION_SET_ALERT_SUCCESS_TEXT,
    ACTION_SET_ALERT_DANGER,
    ACTION_SET_ALERT_DANGER_TEXT,
    ACTION_SET_BROWSER_HISTORY,
    ACTION_SET_NOTIFICATIONS,
    ACTION_SET_FIRST_LOAD_NOTIFICATION,
    ACTION_NOTIFICATION_STATUS,
    ACTION_SET_AMOUNT_NOTIFICATION,
} from '../actions/SiteAction'

export const actionSetNotificationStatus = (payload) => {
    return {
        type: ACTION_NOTIFICATION_STATUS,
        payload: payload
    }
}

export const actionSetAmountNotification = (payload) => {
    return {
        type: ACTION_SET_AMOUNT_NOTIFICATION,
        payload: payload
    }
}

export const actionSetNotifications = (payload) => {
    return {
        type: ACTION_SET_NOTIFICATIONS,
        payload: payload
    }
}
export const actionSetFirstLoadNotification = (payload) => {
    return {
        type: ACTION_SET_FIRST_LOAD_NOTIFICATION,
        payload: payload
    }
}

export const actionSetBrowserHistory = (payload) => {
    return {
        type: ACTION_SET_BROWSER_HISTORY,
        payload: payload
    }
}

export const actionSetAlertSuccess = (payload) => {
    return {
        type: ACTION_SET_ALERT_SUCCESS,
        payload: payload
    }
}

export const actionSetAlertSuccessText = (payload) => {
    return {
        type: ACTION_SET_ALERT_SUCCESS_TEXT,
        payload: payload
    }
}

export const actionSetAlertDanger = (payload) => {
    return {
        type: ACTION_SET_ALERT_DANGER,
        payload: payload
    }
}

export const actionSetAlertDangerText = (payload) => {
    return {
        type: ACTION_SET_ALERT_DANGER_TEXT,
        payload: payload
    }
}