import { $authHost, $host } from './index.js'


export const getFund = async (fund_id) => {
  const {data} = await $host.get('api/fund/get_fund?fund_id=' + fund_id)
  return data
}

export const outFund = async (amount, cpu, max) => {
  const {data} = await $authHost.post('api/fund/out_fund', {
    amount, cpu, max
  })
  return data
}

export const getStatFundVolume = async (fund_id) => {
  const {data} = await $host.get('api/fund/get_stat_volume?fund_id=' + fund_id)
  return data
}

export const getStatProfit = async (fund_id) => {
  const {data} = await $host.get('api/fund/get_stat_profit?fund_id=' + fund_id)
  return data
}

export const getStatFundDistribution = async (fund_id) => {
  const {data} = await $host.get('api/fund/get_stat_fund_distribution?fund_id=' + fund_id)
  return data
}

export const getFunds = async (limit = 20) => {
  const {data} = await $host.get('api/fund/get_funds?limit=' + limit)
  return data
}

export const chooseFund = async (amount, cpu) => {
  const {data} = await $authHost.post('api/fund/choose_fund', {
    amount: amount,
    cpu: cpu,
  })
  return data
}



export const getTotalComission = async () => {
  const {data} = await $authHost.get('api/fund/get_total_comission')
  return data
}

export const getTotalBalance = async () => {
  const {data} = await $authHost.get('api/fund/get_total_balance')
  return data
}

export const getStatProfitPeriod = async (fund_id) => {
  const {data} = await $authHost.get('api/fund/get_stat_profit_period?fund_id=' + fund_id)
  return data
}

export const getBalanceWallet = async () => {
  const {data} = await $authHost.get('api/fund/get_balance_wallet')
  return data
}

export const getBalanceInvestment = async () => {
  const {data} = await $authHost.get('api/fund/get_balance_investment')
  return data
}

export const getBalanceProfitNow = async () => {
  const {data} = await $authHost.get('api/fund/get_balance_profit_now')
  return data
}

export const getComissionSummNotPaid = async() => {
  const {data} = await $authHost.get('api/fund/get_comission_summ_not_paid')
  return data
}

export const getStatUser = async() => {
  const {data} = await $authHost.get('api/fund/get_stat_user')
  return data
}

export const getFundsUser = async () => {
  const {data} = await $authHost.get('api/fund/get_funds_user')
  return data
}
export const getFundByCpu = async (cpu) => {
  const {data} = await $host.get('api/fund/get_fund_by_cpu?cpu=' + cpu)
  return data
}