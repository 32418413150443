import { useSelector } from 'react-redux';
import { prettyUsd } from '../../../utils/consts';
import styles from './StatFund.module.scss';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';

export const StatFund = () => {

  const balance_investment = useSelector((state) => state.fund.balance_investment)
  const balance = useSelector((state) => state.fund.balance)
  const funds = useSelector((state) => state.fund.funds)
  const comission_summ_not_payment = useSelector((state) => state.fund.comission_summ_not_payment)

  const [loading, setLoading] = useState(true)

  useEffect(() => {

    if (
      balance_investment !== null &&
      balance !== null &&
      funds !== null &&
      comission_summ_not_payment !== null
    ) {
      setLoading(false)
    }

  },[balance_investment, balance, funds, comission_summ_not_payment])

  return (
    <div className={styles.body}>
      <div className={styles.title}>Инвестиции</div>
      

      { loading && (
        <div className={styles.container}>

          <div>
            <div className={styles['title-item']}>Инвестировано</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Баланс</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Активных фондов</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Накоп. комиссия</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

        </div>  
      )}
      

      { !loading && (
        <div className={styles.container}>

          <div>
            <div className={styles['title-item']}>Инвестировано</div>
            <div className={styles['amount-item']}>{ prettyUsd(balance_investment) } USD</div>
          </div>

          <div>
            <div className={styles['title-item']}>Баланс</div>
            <div className={styles['amount-item']}>{ prettyUsd(balance) } USD</div>
          </div>

          <div>
            <div className={styles['title-item']}>Активных фондов</div>
            <div className={styles['amount-item']}>{ funds.length } шт.</div>
          </div>

          <div>
            <div className={styles['title-item']}>Накоп. комиссия</div>
            <div className={styles['amount-item']}>{ prettyUsd(comission_summ_not_payment) } USD</div>
          </div>

        </div>  
      )}
      

    </div>
  );
};
