import { ActionButton, Input, PasswordInput } from '../../ui-kit/components';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { google_auth, google_code, reset_2fa } from '../../http/userAPI';

import styles from './Google2FAReset.module.scss';
import { MAIN_ROUTER, GOOGLE_AUTH_ROUTER, alertDanger, alertSuccess, SIGNIN_ROUTER } from '../../utils/consts';
import { useEffect } from 'react';
import { Logo } from '../../ui-kit/assets';
import { RedCircle } from '../../ui-kit/assets';

export const Google2FAReset = () => {

  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')

  const dispatch = useDispatch()


  const navigate = useNavigate()


  const browser_history = useSelector((state) => state.site.browser_history)
  const close = () => {
    navigate(browser_history[0])
  }

  const reset = async () => {

    try {
      let data = await reset_2fa(email, password)
      alertSuccess(dispatch, data.message)
      navigate(SIGNIN_ROUTER)
    } catch (e) {
      alertDanger(dispatch, e.response.data.message)
    }
    
  }

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          <div style={{textAlign: 'center', marginBottom: 0}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>
          <h1 className={styles.title}>Сброс двухфакторной аутентификации</h1>
          <div className={styles.form}>
            <div className={styles.text}>Эта страница поможет вам сбросить двухфакторную аутентификацию (2FA).</div>
            <div className={styles.text}>После подтверждения по электронной почте ваш 2FA будет сброшен, а на вашем аккаунте будет временно приостановлен вывод средств.  Срок запрета на вывод составит 14 календарных дней.</div>
            
            <div className={styles.container}>
              <Input className={styles.input} setInputValue={setEmail} label='E-mail' dynamicLabel />
              <PasswordInput setInputValue={setPassword} className={styles.input} />
            </div>

            <div className={styles.buttons}>
              <ActionButton onClick={() => close()} className={styles.button_cancel}>Отменить</ActionButton>
              <ActionButton onClick={() => reset()} className={styles.button}>Отправить</ActionButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
