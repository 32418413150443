import {
    ACTION_SET_BALANCE,
    ACTION_SET_ACTIVE_PAYMENTS,
    ACTION_SET_PLANS,
    ACTION_SET_COMISSION,
    ACTION_SET_GRAPH,
} from '../actions/PaymentAction'

const initialState = {
    balance: {},
    active_payments: [],
    plans: [],
    comission: 0,
    graph: [],
};


const paymentReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_BALANCE: return { ...state, balance: action.payload };
        case ACTION_SET_ACTIVE_PAYMENTS: return { ...state, active_payments: action.payload };
        case ACTION_SET_PLANS: return { ...state, plans: action.payload };
        case ACTION_SET_COMISSION: return { ...state, comission: action.payload };
        case ACTION_SET_GRAPH: return { ...state, graph: action.payload };
        default: return state;
    }
}

export default paymentReducer