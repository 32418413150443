import { $authHost, $host } from './index.js'

export const getNews = async (limit = 12) => {
  const {data} = await $host.get('api/site/get_news?limit=' + limit)
  return data
}

export const getNotifications = async () => {
  const {data} = await $authHost.get('api/site/get_notifications')
  return data
}

export const setNotificationReaded = async (notification_id) => {
  const {data} = await $authHost.post('api/site/set_notification_readed', {
    notification_id
  })
  return data
}

