import classNames from 'classnames';
import { useMemo } from 'react';
import {
  Input,
  Select,
  Textarea,
  ActionButton,
  Table,
  ColoredText,
  HollowButton,
  Card,
} from '../../ui-kit/components';

import styles from './Support.module.scss';

export const Support = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Дата',
        accessor: 'date',
        Cell: ({ value }) => <p className={styles['table__bold-text']}>{value}</p>,
      },
      {
        Header: 'Тема запроса',
        accessor: 'topic',
        Cell: ({ value }) => <p className={styles['table__bold-text']}>{value}</p>,
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => (
          <ColoredText color={value ? 'green' : 'red'}>{value ? 'Открыт' : 'Завершён'}</ColoredText>
        ),
      },
      {
        Header: 'Информация',
        accessor: 'information',
        Cell: ({ value }) => (
          <HollowButton
            className={!value ? styles['hide-button'] : ''}
            type='link'
            to='/support/information'
          >
            Информация
          </HollowButton>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      { date: '22/04/2022 22:22', topic: 'Ошибка в торговле', status: true, information: true },
      { date: '22/04/2022 22:22', topic: 'Ошибка в торговле', status: false, information: false },
    ],
    []
  );

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Поддержка</h1>
      <form className={styles.form}>
        <div className={styles.form__inputs}>
          <div className={styles['input-list']}>
            <Input
              className={styles.input}
              inputClassName={styles.input__input}
              label='Тема запроса'
            />
            <Select className={styles.input} placeholder='Ваш запрос' />
          </div>
          <Textarea className={styles.textarea} placeholder='Опишите проблему' />
        </div>
        <ActionButton className={styles.form__button}>Создать запрос</ActionButton>
      </form>
      <Table
        className={styles['desktop-element--block']}
        columns={columns}
        data={data}
        rowClassName={styles.table__row}
      />
      <Card className={classNames(styles.requests, styles['mobile-element--block'])}>
        {data.map(({ date, topic, status, information }) => (
          <div className={styles['request-item']}>
            <p className={styles['request-item__date']}>{date}</p>
            <div className={styles['request-item__info']}>
              <p className={styles['table__bold-text']}>{topic}</p>
              <ColoredText color={status ? 'green' : 'red'}>
                {status ? 'Открыт' : 'Завершен'}
              </ColoredText>
            </div>
            {information && (
              <HollowButton
                type='link'
                to='/support/information'
                className={styles['request-item__button']}
              >
                Информация
              </HollowButton>
            )}
          </div>
        ))}
      </Card>
    </div>
  );
};
