import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ColoredText, ActionButton } from '../../../ui-kit/components';
import { LandingPageCard } from '../LandingPageCard';
import { AVAX, MainImage } from '../../../ui-kit/assets';
import BTC from '../LandingPageCard/assets/BTC.svg';

import styles from './HeroSection.module.scss';
import { useSelector } from 'react-redux';
import { FUNDS_ROUTER, SIGNIN_ROUTER } from '../../../utils/consts';

export const HeroSection = () => {
  const { t } = useTranslation(['landing-page', 'common']);
  const isAuth = useSelector((state) => state.user.isAuth)
  return (
    <section className={styles.body}>
      <div className={styles['text-block']}>
        <h1 className={styles.title}>
          Приватность. Инвестиции. USD
        </h1>
        <p className={styles.text}>Присоединяйтесь к миру социального трейдинга на крипторынке. Инвестируйте в лучших портфельных управляющих. Создайте свой проект и зарабатывайте вместе с инвесторами</p>
        { isAuth && <ActionButton className={styles.button} size="sm" type='link' to={ FUNDS_ROUTER }>Выбрать фонд</ActionButton> }
        { !isAuth && <ActionButton className={styles.button} size="sm" type='link' to={ SIGNIN_ROUTER }>Войти</ActionButton> }
      </div>
      <div className={styles.cards}>
        <img className={styles.image} src={MainImage} alt=""/>
      </div>
    </section>
  );
};
