import { Link, useNavigate } from 'react-router-dom'
import { ActionButton, Input, PasswordInput } from '../../ui-kit/components'
import styles from './SignIn.module.scss'
import { useState } from 'react'
import { finishLogin, login } from '../../http/userAPI'
import store from '../../store/store'
import { 
  actionSetGoogleAuth,
  actionSetIsAuth,
  actionSetStopCheck2fa,
  actionSetUserData
} from '../../store/actionCreators/userActionCreator'
import { alertDanger, DASHBOARD_ROUTER, GOOGLE_2FA_RESET, GOOGLE_AUTH_INFO_ROUTER, GOOGLE_AUTH_ROUTER, MAIN_ROUTER } from '../../utils/consts'
import { useSelector, useDispatch } from 'react-redux'
import { Logo } from '../../ui-kit/assets';

export const SignIn = () => {

  const dispatch = useDispatch()
  
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')
  const [title, setTitle] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [check_code, setCheckCode] = useState(false)
  const [google_auth, setGoogleAuth] = useState(0)
  const [stop_check_2fa, setStopCheck2fa] = useState(0)
  

  const navigate = useNavigate()

  const isAuth = useSelector(store => store.user.isAuth)

  const finishSendForm = async (e, vl_code = null) => {

    setCheckCode(true)

    e.preventDefault()

    if (isAuth) navigate(MAIN_ROUTER)

    if (vl_code == null) vl_code = code

    try {
      let data = await finishLogin(email, password, vl_code)
      
      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 
      dispatch(actionSetGoogleAuth(data.google_auth)) 
      dispatch(actionSetStopCheck2fa(data.stop_check_2fa)) 

      if (data.google_auth == 0) {
        navigate(GOOGLE_AUTH_INFO_ROUTER)
      } else {
        navigate(DASHBOARD_ROUTER)
      }

    } catch(e) {
        
      alertDanger(dispatch, e.response.data.message)

    }

    setCheckCode(false)
    
  }

  const setInputValueCode = async (val) => {
    setCode(val)
    if (val.length == 6) {
      await finishSendForm(window.event, val)
    }
  }

  const sendForm = async (e) => {

    if (isAuth) navigate(MAIN_ROUTER)

    e.preventDefault()

    try {

      let data = await login(email,password)

      setGoogleAuth(data.google_auth)

      if (data.google_auth == 0) {

        setTitle('Введите код подтверждения, отправленный на Email')
        setPlaceholder('Код подтверждения')

      } else if (data.google_auth == 1) {

        setTitle('2FA токен из Google-аутентификатора')
        setPlaceholder('6 цифр')

      } else if (data.google_auth == -1) {

        console.log("-1")
        
        let data = await finishLogin(email, password, "0000")
      
        dispatch(actionSetIsAuth(true))
        dispatch(actionSetUserData(data)) 
        dispatch(actionSetGoogleAuth(data.google_auth)) 
  
        navigate(DASHBOARD_ROUTER)

        return

      }
      
      setStep(2)
      
    } catch(e) {
        
      alertDanger(dispatch, e.response.data.message)

    }
    
  }

  const browser_history = useSelector((state) => state.site.browser_history)
  const close = () => {
    navigate(browser_history[0])
  }


  
  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles[step == 1 ? 'content' : 'content2']}>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>
          <h1 className={styles.title}>Вход для клиентов</h1>
          <form className={styles.form}>
            
            { step == 1 && (
              <>
                <Input className={styles.input} setInputValue={setEmail} label='E-mail' dynamicLabel />
                <PasswordInput setInputValue={setPassword} className={styles.input} />
                <div className={styles.links}>
                  
                  <Link className={styles.link} to='/password-restore'>
                    Забыли пароль?
                  </Link>

                  { false && (
                    <Link className={styles.link} to='/sign-up'>
                      Регистрация
                    </Link>  
                  )}
                  
                </div>
              </>
            ) }

            { step == 2 && (
              <>
                <div style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>{ title }</div>
                <div style={{marginBottom: 10, marginTop: 10, width: "100%"}}>
                  <div className={styles.container_input}><Input autocomplete="off" setInputValue={setInputValueCode} label={ placeholder } dynamicLabel /></div>
                </div>
              </>
            ) }

            { step == 1 && (
              <>
                <ActionButton onClick={(e) => sendForm(e)} className={styles.button}>Далее</ActionButton>
              </>
            )}

            { step == 2 && (
              <>
                { google_auth == 1 && <Link to={GOOGLE_2FA_RESET}><div className={styles.text_info}>Нет доступа к Google-аутентификатору?</div></Link> }
                <div className={styles.form_footer}>
                  <ActionButton onClick={() => close()} className={styles.button_cancel}>Отменить</ActionButton>
                  { check_code == false ? (
                    <>
                      <ActionButton onClick={(e) => finishSendForm(e) } className={styles.button}>Далее</ActionButton>
                    </>
                  ) : (
                    <>
                      <ActionButton className={styles.button_check}>Проверка...</ActionButton>
                    </>
                  )}
                </div>
              </>
            ) }


          </form>
        </div>
      </div>
    </>
  );
};
