import {
    ACTION_SET_ALERT_SUCCESS,
    ACTION_SET_ALERT_SUCCESS_TEXT,
    ACTION_SET_ALERT_DANGER,
    ACTION_SET_ALERT_DANGER_TEXT,
    ACTION_SET_BROWSER_HISTORY,
    ACTION_SET_NOTIFICATIONS,
    ACTION_SET_FIRST_LOAD_NOTIFICATION,
    ACTION_NOTIFICATION_STATUS,
    ACTION_SET_AMOUNT_NOTIFICATION,
} from '../actions/SiteAction'


const initialState = {
    alert_success: false,
    alert_success_text: '',
    alert_danger: false,
    alert_danger_text: '',
    browser_history: ['/'],
    notifications: [],
    first_load_notification: true,
    notification_status: 1, // 1 - запущен, 0 - остановлен
    notification_amount: 0,
};


const siteReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_ALERT_SUCCESS: return { ...state, alert_success: action.payload }
        case ACTION_SET_ALERT_SUCCESS_TEXT: return { ...state, alert_success_text: action.payload }
        case ACTION_SET_ALERT_DANGER: return { ...state, alert_danger: action.payload }
        case ACTION_SET_ALERT_DANGER_TEXT: return { ...state, alert_danger_text: action.payload }
        case ACTION_SET_NOTIFICATIONS: return { ...state, notifications: action.payload }
        case ACTION_SET_FIRST_LOAD_NOTIFICATION: return { ...state, first_load_notification: action.payload }
        case ACTION_NOTIFICATION_STATUS: return { ...state, notification_status: action.payload }
        case ACTION_SET_AMOUNT_NOTIFICATION: return { ...state, notification_amount: action.payload }
        case ACTION_SET_BROWSER_HISTORY: 
            state.browser_history.unshift(action.payload)
            return state
        default: return state
    }
}

export default siteReducer